@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import 'nebular-icons/css/nebular-icons.css';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';
@import './fonts';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();

  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-cell, .mat-header-cell {
  padding: 1rem .5rem !important;
}

:root {
  --color-green-logo: #4CAE32;
  --color-green-medium: #4BA22E;
  --color-green: #3D9E31;
  --color-green-light: rgba(61, 158, 49, 0.2);
  --color-green-lit: #0DD11C;

  --color-purple-black: #3C4FFD;

  --color-white: #FFFFFF;
  --color-white-light: #F4F6FA;
  --color-white-gray: #E9E9E9;
  --color-gray: #A6A6A6;

  --color-black: #000000;
  --color-black-secondary: #030503;

  //button colors light

  --button-color: #3C53F4;

  --background-dash: #D2D2D2;
  --background-card-dash: #E1E1E1;
  --background-tab-dash: #CDCDCD;
  --background-active-tab-dash: #EFEFEF;
  --color-font-card-dash: #00263F;
  --color-warning: #e63030;
}

.upload-image {
  $imageSize: 19rem;
  min-width: $imageSize;
  width: $imageSize;
  height: 100%;
  border-radius: 8px;
  border: 2px solid var(--color-black);
  object-fit: cover;
  object-position: center;
}

.white-space-pre {
  white-space: pre !important;
}

.disabled {
  opacity: 0.6;
}
