@mixin ngx-pace-theme() {

  .pace .pace-progress {
    background: var(--color-white);
  }

  .pace .pace-progress-inner {
    box-shadow: 0 0 10px var(--color-white), 0 0 5px var(--color-white);
  }

  .pace .pace-activity {
    display: none;
  }
}
