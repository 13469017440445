@font-face {
    font-family: "Poppins";
    src: url('../../../assets/fonts/Poppins-Regular.ttf') format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Poppins";
    src: url('../../../assets/fonts/Poppins-Bold.ttf') format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Poppins";
    src: url('../../../assets/fonts/Poppins-Medium.ttf') format("truetype");
    font-weight: 500;
}
